import React, {useState,useEffect} from "react";
import {Modal,Button} from 'react-bootstrap'

const TimeSheetConfirmModal = (props) => {
    let [modalStatus, setModal ] = useState(false)
    // console.log('From Timesheet modal confirm ',modalStatus)
    // if(props.type === '')
    let content = null
    let buttonText = 'Yes'
    let headerFontColor = 'black'
    if(props.type === 'LOCK'){
        buttonText = 'Lock'
        content = (
            <div>
                <p style={{marginBottom:0}}>Are you sure you want to lock this timesheet?</p>
                <small>Once the timesheet is set as locked, employees will not be able to edit it</small>
            </div>
        )
    }
    else if (props.type === 'UNLOCK'){
        buttonText = 'Unlock'
        content = (<div>
        <p>Are you sure you want to unlock this timesheet?</p>
        {/* <small>Once the timesheet is set as locked, employees will not be able to edit it</small> */}
    </div>)
    }else if (props.type === 'APPROVE'){
        buttonText = 'Approve'
        headerFontColor = 'green'
       content = ( <div>
        <p>Are you sure you want to approve this timesheet?</p>
        {/* <small>Once the timesheet is set as locked, employees will not be able to edit it</small> */}
    </div>)
    }else if (props.type === 'UNAPPROVE'){
        buttonText = 'Unapprove'
        headerFontColor = 'red'
       content = ( <div>
        <p>Are you sure you want to unapprove this timesheet?</p>
        {/* <small>Once the timesheet is set as locked, employees will not be able to edit it</small> */}
    </div>)
    }else {
        content = (
            <div>
                <p>Are you sure you want to {props.status === "SUBMITTED" ? "reject" : "submit"} this timesheet?</p>
            </div>
        );
    }


    return (
        <div 
        onClick={()=> {
            if(!props.disabled){
                setModal(!modalStatus)}
            }
        }
        >
            {props.children}
             <Modal show={modalStatus} onHide={()=> setModal(false)}>
                        <Modal.Header style={{display: 'block'}}>
                        <Modal.Title >
                            <p className="text-center" style={{margin: 5,color: headerFontColor}}>
                            {props.type === 'LOCK'? 
                        'LOCK TIMESHEET':props.type === 'UNLOCK'?
                        'UNLOCK TIMESHEET':props.type === 'APPROVE'?'APPROVE TIMESHEET':props.type === 'UNAPPROVE'?'UNDO APPROVE': (props.status === "SUBMITTED" ? "REJECT" : "SUBMIT") + ' TIMESHEET'}
                        </p>
                        </Modal.Title>
                        </Modal.Header>
                        <center><Modal.Body>
                            {content}
                            {/* {props.type === 'LOCK'? 
                        'Are you sure you want to lock ':props.type === 'UNLOCK'?
                        'UNLOCK TIMESHEET':props.type === 'APPROVE'?'APPROVE TIMESHEET':props.type === 'UNAPPROVE'?'UNDO APPROVE': 'SUBMIT TIMESHEET'} */}
                            {/* Are you sure you want to delete this contact? */}
                            </Modal.Body></center>
                        {/* <Modal.Footer> */}
                        <div className='d-flex flex-row justify-content-center m-3'>
                        <Button className='mr-1' variant="secondary" onClick={()=> setModal(false)}>
                            Back
                        </Button>
                        <Button className='mr-1' variant="primary" onClick={async()=> { 
                            await props.onClick()
                            setModal(false)
                            }
                        }
                        >
                            {buttonText}
                        </Button>
                        </div>
                        {/* </Modal.Footer> */}
                    </Modal>
                   
        {/* {props.children} */}
        </div>
    )
}

export default TimeSheetConfirmModal